import { selectTagsFromPost, selectCategoryFromPost } from './selectPosts';

export const selectPostsFromResults = results => {
  return results.map(({
    category,
    tags,
    ...rest
  }) => ({
    ...rest,
    tags: selectTagsFromPost(tags),
    category: selectCategoryFromPost(category)
  }));
};
