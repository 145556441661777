import React, { useEffect, useState } from 'react';
import PageLayout from '../components/PageLayout';
import MetaTags from '../components/MetaTags';
import Search from '../components/Search';
import { selectPostsFromResults } from '../selectors/selectResults';

const SearchPage = ({
  location: {
    pathname,
    href,
    search
  }
}) => {
  const title = 'Search posts';
  const description = 'Search through all articles, tutorials, snippets and external links on Assortment.';

  const [results, setResults] = useState([]);
  const searchQuery = new URLSearchParams(search).get('q') || '';

  useEffect(() => {
    if (searchQuery && window.__LUNR__) {
      window.__LUNR__.__loaded.then(lunr => {
        const results = lunr.en.index.search(searchQuery);
        const posts = selectPostsFromResults(results.map(({ ref }) => lunr.en.store[ref]));
        setResults(posts);
      });
    }
  }, [searchQuery]);

  return (
    <PageLayout>
      <MetaTags
        url={href}
        metaTitle={title}
        metaDescription={description}
      />
      <Search
        query={searchQuery}
        pathname={pathname}
        results={results}
      />
    </PageLayout>
  );
};

export default SearchPage;
