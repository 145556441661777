import React from 'react';
import { navigate } from 'gatsby';
import Post from '../Post';
import {
  InputWrapper,
  Title,
  Label,
  Input,
  Note,
  Results,
  ResultsItem
} from './styled';

const Search = ({
  query,
  pathname,
  results
}) => (
  <div
    role="combobox"
    aria-expanded={!!results.length}
    aria-haspopup="listbox"
    aria-labelledby="search-label"
    aria-controls="search-results"
    aria-owns="search-results"
  >
    <InputWrapper>
      <Title>Search posts</Title>
      <Label
        id="search-label"
        htmlFor="search-input"
      >Search Assortment for tutorials, Articles, Snippets and Links.</Label>
      <Input
        id="search-input"
        type="text"
        autoComplete="off"
        placeholder="i.e. React Portals"
        value={query}
        onChange={(e) => navigate(`${pathname}?q=${encodeURIComponent(e.target.value)}`)}
        aria-labelledby="search-label"
        aria-controls="search-results"
      />
      {!!results.length && <Note>{`Found ${results.length} results for '${query}'`}</Note>}
    </InputWrapper>
    <Results
      id="search-results"
      role="listbox"
      aria-labelledby="search-label"
    >
      {results && results.map(post => <ResultsItem key={post.id}><Post {...post} /></ResultsItem>)}
    </Results>
  </div>
);

export default Search;
