import styled from '@emotion/styled';
import { baseColors, breakpoints } from '../../constants';

export const Results = styled.ol`
  list-style: none;
  max-width: 750px;
  margin: 0 auto;
  padding: 0;
`;

export const ResultsItem = styled.li`
  margin-bottom: 1rem;
`;

export const InputWrapper = styled.div`
  padding: 4rem 2rem;
  text-align: center;

  @media screen and (min-width: ${breakpoints.bp2}) {
    padding: 4rem;
  }
`;

export const Title = styled.h1`
  margin-bottom: 1rem;
`;

export const Label = styled.label`
  display: block;
  font-size: .8rem;
`;

export const Input = styled.input`
  appearance: none;
  min-width: 280px;
  margin-top: .5rem;
  padding: .5rem 1rem;
  font-size: 1rem;
  border: 1px solid ${baseColors.grey.base};
  border-radius: 1rem;
  transition: all .15s ease-out;

  &:focus {
    outline: 0;
    border-color: #BBBBBB;
  }
`;

export const Note = styled.p`
  margin-top: .25rem;
  font-size: .6rem;
  color: ${baseColors.grey.dark};
`;
